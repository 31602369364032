import styles from './index.css';
import Settings from '../../helpers/settings';

export default function ({ selector, title, description }) {
  const { launcher: { borderRadius } = {} } = Settings.getAll();

  const descriptionElement = description
    ? `<p class="${styles.buttonDescription}">${description}</p>`
    : '';
  const element = document.createElement('div');
  element.className = `${selector} ${styles.socialButton}`;
  element.style.setProperty('--guuru-button-border-radius', borderRadius === '0%' ? '0' : '8px');
  element.innerHTML = `
    <p class="${styles.buttonTitle}">${title}</p>
    ${descriptionElement}
    <div class="${styles.buttonArrow}">
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.78 5.28c.14.14.22.331.22.53v.38a.77.77 0 01-.22.53l-5.14 5.13a.5.5 0 01-.71 0l-.71-.71a.49.49 0 010-.7L4.67 6 .22 1.56a.5.5 0 010-.71l.71-.7a.5.5 0 01.71 0l5.14 5.13z" fill="#fff"></path></svg>
    </div>`;
  return element.outerHTML;
}
