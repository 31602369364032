import styles from './index.css';
import Constants from '../../../../../helpers/constants';
import Settings from '../../../../../helpers/settings';
import Vanilla from '../../../../../helpers/vanilla';
import Analytics from '../../../../analytics';
import Events from '../../../../events';

const actionButton = (isVisible) => {
  if (!isVisible) return '';
  const {
    enabled,
    locale = 'en',
    contentEmbed: {
      contentEmbedPaginatedButtonLabel,
    } = {},
    launcher: { borderRadius } = {},
  } = Settings.getAll();

  if (!enabled) return '';

  Vanilla.live(`.${Constants.SELECTOR.contentEmbed}`, ['click'], () => {
    Analytics.trackEvent('content_embed_interaction', 'chat');
    Events.processEvent({
      id: `${Constants.GUURU_EVENT_NAMESPACE}embed:interaction`,
      data: { type: 'content', element: 'chat' },
    });
  });

  const element = document.createElement('button');
  element.className = `${Constants.SELECTOR.contentEmbed} ${styles.contentEmbedPaginatedButton}`;
  element.style.setProperty('--guuru-button-border-radius', borderRadius === '0%' ? '0' : '8px');
  element.innerHTML = contentEmbedPaginatedButtonLabel?.[locale];
  return element.outerHTML;
};

export default actionButton;
