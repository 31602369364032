import Settings from '../../helpers/settings';
import styles from './index.css';

export default function (customClass = '') {
  const {
    appId,
    locale,
    translations: { poweredBy },
  } = Settings.getAll();

  return `
   <div class="${styles.poweredBy} ${customClass}" >
    <a
      href="${`https://www.guuru.com/${locale}/?utm_source=partnerWebsite&utm_medium=mentions&utm_campaign=mentions&utm_term=${appId}&utm_content=${appId}`}"
      target="_blank"
      rel="noopener nofollow"
      aria-label="Visit Guuru website"
    >
        <img src="https://cdn.guuru.com/assets/logo/guuru_logo_outlined.svg" alt="Guuru Logo" /> ${poweredBy[locale]}
    </a>
   </div>
  `;
}
