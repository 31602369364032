const CONSTANTS = {
  FORM_REFERER_TRIGGER: 'smartForm',
  DEVICE_TABLET_MIN_RES: 740,
  DEVICE_DESKTOP_MIN_RES: 1280,
  DEFAULT_LAUNCHER_ICON: 'comments',
  PROACTIVE_CHATS: 'autoMessages',
  PROACTIVE_CHAT_SIGNAL_ACTION: 'hasNewMessage',
  PROACTIVE_CHAT_ATTENTION_GRABBER_ACTION: 'attentionGrabber',
  PROACTIVE_CHAT_INVITATION_GRABBER_ACTION: 'invitationGrabber',
  PROACTIVE_CHAT_OPEN_ACTION: 'chatOpen',
  PREVENT_PROACTIVE_CHAT: 'preventProactiveChat',
  CHAT_LOADER_OPEN: 'chatLoaderOpen',
  GUURU_EVENT_NAMESPACE: 'guuru::',
  GUURU_STATE: 'guuru-state',
  SUPPORTED_LOCALES: [
    'en',
    'de',
    'fr',
    'it',
    'es',
    'th',
    'ko',
    'ja',
    'nl',
    'pl',
    'da',
    'no',
    'sv',
    'fi',
    'pt',
    'zh',
    'ar',
    'ro',
    'uk',
    'bg',
    'cs',
    'sl',
  ],
  SUPPORTED_LAUNCHER_ICONS: ['comments'],
  SUPPORTED_LAUNCHER_SIZES: ['small', 'medium', 'large'],
  SUPPORTED_AG_TARGET_DEVICES: ['mobile', 'tablet', 'desktop'],
  SUPPORTED_LAUNCHER_POSITIONS: ['bottomRight', 'bottomLeft'],
  ALLOWED_CHAT_PARAMETERS: [
    'channel',
    'referer',
    'refererTrigger',
    'refererPath',
    'refererType',
    'locale',
    'category',
    'payloadName',
    'payloadEmail',
    'payloadUserLocale',
    'payloadQuestion',
    'customMeta',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
    'utm_term',
    'token',
    'priority',
    'spaces',
    'waitSignal',
  ],
  MILLISECONDS_TO_VERIFY_UNREAD_MESSAGES: 5000,
};

CONSTANTS.VALIDATION = {
  APP_ID: "[ Guuru Exception | 'appId' is a mandatory parameter. Guuru Chat Button cannot be initialized without it. ]",
  LOCALES: `[ Guuru Exception | 'locale' is not valid. Guuru Chat Button accepts only [${CONSTANTS.SUPPORTED_LOCALES}] as locale. ]`,
  OPEN_TRIGGERS: "[ Guuru Exception | 'openTriggers' is not valid. Guuru Chat Button accepts only an array of strings as openTriggers. ]",
  LAUNCHER_POSITIONS: `[ Guuru Exception | 'launcher.position' is not valid. Guuru Chat Button accepts only [${CONSTANTS.SUPPORTED_LAUNCHER_POSITIONS}] as 'launcher.position'. ]`,
  LAUNCHER_ICONS: `[ Guuru Exception | 'launcher.icon' is not valid. Guuru Chat Button accepts only [${CONSTANTS.SUPPORTED_LAUNCHER_ICONS}] as 'launcher.icon'. ]`,
  LAUNCHER_SIZES: `[ Guuru Exception | 'launcher.size' is not valid. Guuru Chat Button accepts only [${CONSTANTS.SUPPORTED_LAUNCHER_SIZES}] as 'launcher.size'. ]`,
  LOAD_FEATURES: (invalidLoadedFeatures) => `[ Guuru Exception | ${invalidLoadedFeatures} is not valid. Guuru Chat Button accepts only boolean values for 'loadFeatures' object keys. ]`,
};

CONSTANTS.ICONS = {
  close: '<svg viewBox="-60 0 512 512"><path fill="currentColor" d="M217.5 256l137.2-137.2c4.7-4.7 4.7-12.3 0-17l-8.5-8.5c-4.7-4.7-12.3-4.7-17 0L192 230.5 54.8 93.4c-4.7-4.7-12.3-4.7-17 0l-8.5 8.5c-4.7 4.7-4.7 12.3 0 17L166.5 256 29.4 393.2c-4.7 4.7-4.7 12.3 0 17l8.5 8.5c4.7 4.7 12.3 4.7 17 0L192 281.5l137.2 137.2c4.7 4.7 12.3 4.7 17 0l8.5-8.5c4.7-4.7 4.7-12.3 0-17L217.5 256z"></path></svg>',
  comments: '<svg viewBox="0 -40 576 540"><path fill="currentColor" d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"/></svg>',
};

CONSTANTS.STYLES = {
  ATTENTION_GRABBER: {
    desktop: 'mod--ag-d',
    tablet: 'mod--ag-t',
    mobile: 'mod--ag-m',
  },
  SIGNAL: {
    desktop: 'mod--signal-d',
    tablet: 'mod--signal-t',
    mobile: 'mod--signal-m',
  },
};

CONSTANTS.SELECTOR = {
  launcher: '#GuuruMain .js-guuru-launcher-button',
  grabber: '#GuuruMain .js-guuru-launcher-grabber',
  grabberClose: '#GuuruMain .js-guuru-launcher-grabber-close',
  attentionGrabberContent: '#GuuruMain .js-guuru-launcher-attentionGrabber-content',
  invitationGrabberContent: '#GuuruMain .js-guuru-launcher-invitationGrabber-content',
  communityEmbed: 'guuru-community-embed',
  communityEmbedExtended: 'guuru-community-embed-extended',
  contentEmbed: 'guuru-content-embed',
};

export default CONSTANTS;
