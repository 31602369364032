import Settings from '../../helpers/settings';
import CONSTANTS from '../../helpers/constants';
import Analytics from '../analytics';
import AutoMessagesStorage from '../autoMessages/storage';
import styles from './attention.css';
import Vanilla from '../../helpers/vanilla';

const handleAttentionGrabberClick = ({ id, title }) => {
  AutoMessagesStorage.setAutoMessageAsRead(id);
  Settings.add('refererTrigger', `Chat Button (${title})`);
  window.guuru.toggleChat();
  Analytics.trackEvent('attention_grabber_click');
};

const attachEventListeners = ({ title, id }) => {
  Vanilla.addEvents(
    document.querySelector(CONSTANTS.SELECTOR.attentionGrabberContent),
    ['click'],
    () => handleAttentionGrabberClick({ id, title }),
  );
};

const onLoad = ({ id, title }) => {
  attachEventListeners({ id, title });
  Analytics.trackEvent('attention_grabber_shown');
};

const getStyles = function () {
  const {
    launcherColor,
    launcherTextColor,
  } = Settings.getAll();

  const attentionTextStyles = `.js-guuru-launcher-grabber span {color: ${launcherTextColor};}`;
  const attentionBackgroundStyles = `.js-guuru-launcher-attentionGrabber-content{background-color: ${launcherColor};}`;

  return attentionBackgroundStyles + attentionTextStyles;
};

const getHtml = function (content) {
  return (`
    <div class='js-guuru-launcher-attentionGrabber-content ${styles.attentionGrabberContent}'>
      <span class='js-guuru-launcher-attentionGrabber-message ${styles.attentionGrabberMessage}'>
      ${content}
      </span>
    </div>
  `);
};

export default {
  getHtml,
  getStyles,
  onLoad,
};
