import Launcher from './launcher';
import AutoMessages from './autoMessages';
import Vanilla from '../helpers/vanilla';
import CONSTANTS from '../helpers/constants';
import Grabber from './grabber';
import Settings from '../helpers/settings';
import ChatWidget from './chatWidget';
import Conversion from './conversion';
import DisplayRules from './displayRules';
import PageTitle from './pageTitle';
import Analytics from './analytics';
import CommunityEmbed from './embed';

const { debounce } = Vanilla;

const handleFullScreenStateChange = function () {
  const el = document.querySelector(CONSTANTS.SELECTOR.launcher);
  if (el) {
    if (document.fullscreenElement
      || document.webkitFullscreenElement
      || document.mozFullScreenElement
      || document.msFullscreenElement
    ) {
      el.style.display = 'none';
    } else {
      el.style.display = '';
    }
  }
};

const handleLauncherClick = function () {
  if (Grabber.isVisible()) {
    const { title } = Grabber.getCurrentGrabber();
    Settings.add('refererTrigger', `Chat Button (${title})`);
  } else if (AutoMessages.isSignalVisible()) {
    const { title } = AutoMessages.current.signal;
    Settings.add('refererTrigger', title);
  }
  Analytics.trackEvent(ChatWidget.isVisible()
    ? 'chat_button_close_click'
    : 'chat_button_click');
  ChatWidget.toggleChat();
};

const handleStateUpdate = function () {
  Conversion.runConversionGoals();
  if ('guuru' in window) {
    CommunityEmbed.attachEmbeds();
    AutoMessages.initInvitationContent();
  }
  if (!ChatWidget.isVisible() && (
    !Launcher.isEnabledByUrl()
    || !DisplayRules.isEnabledByCategory()
    || (DisplayRules.isHideOnLoad() && !Launcher.isChatLoaderVisible())
  )) {
    Launcher.hideChatLoader();
  } else if ('guuru' in window) {
    Launcher.showChatLoader();
    // Recalculate position: some sticky elements might not be in the
    // new page anymore.
    Launcher.recalculateChatElementPositions();
  }
};

const handleScroll = function () {
  Launcher.recalculateChatElementPositions();
  const { launcher: { hideUntilScroll } = {} } = Settings.getAll();
  if (hideUntilScroll) {
    Vanilla.removeClass(document.body, 'guuru-chat-hideUntilScroll');
  }
};

const overridePushState = function () {
  const { pushState } = window.history;
  window.history.pushState = function (...args) {
    const [state] = args;
    const historyState = pushState.apply(window.history, args);
    const evt = document.createEvent('Event');
    evt.initEvent('guuru:pushstate', state, true);
    document.dispatchEvent(evt);
    return historyState;
  };
};

const initializeHideUntilScroll = function () {
  const {
    launcher: {
      hideUntilScroll,
      hideUntilScrollTargetDevices,
    } = {},
  } = Settings.getAll();

  if (hideUntilScroll
    && hideUntilScrollTargetDevices
    && hideUntilScrollTargetDevices.length > 0
  ) {
    Vanilla.addClass(document.body, 'guuru-chat-hideUntilScroll');
    setTimeout(() => {
      if (Vanilla.isBottomOfPageVisible()) {
        Vanilla.removeClass(document.body, 'guuru-chat-hideUntilScroll');
      }
    }, 3000);
  }
};

const removeWindowListeners = function () {
  window.removeEventListener('scroll', handleScroll, false);
  window.removeEventListener('scrollend', handleScroll, false);
};

const initializeListeners = function () {
  document.querySelector(CONSTANTS.SELECTOR.launcher)
    .addEventListener('click', handleLauncherClick);

  window.addEventListener('scroll', debounce(() => handleScroll()), false);
  window.addEventListener('scrollend', debounce(() => handleScroll()), false);

  document.addEventListener('guuru:pushstate', handleStateUpdate, false);
  window.addEventListener('popstate', handleStateUpdate);

  // Full screen event listeners
  document.addEventListener(
    'webkitfullscreenchange',
    handleFullScreenStateChange,
  );

  document.addEventListener(
    'mozfullscreenchange',
    handleFullScreenStateChange,
  );

  document.addEventListener(
    'MSFullscreenChange',
    handleFullScreenStateChange,
  );

  document.addEventListener(
    'fullscreenchange',
    handleFullScreenStateChange,
  );

  document.addEventListener(
    'visibilitychange',
    PageTitle.handleResetNotificationCounter,
  );
  const { openTriggers } = Settings.getAll();
  if (openTriggers.length > 0) {
    Vanilla.live(openTriggers, ['click', 'touchend'], (event) => {
      const refererTrigger = event?.target?.getAttribute('data-refererTrigger');
      Settings.add('refererTrigger', refererTrigger || 'customLauncher');
      event.preventDefault();
      event.stopImmediatePropagation();
      ChatWidget.showChat();
    });
  }
};

const HELPER = {
  initializeListeners,
  removeWindowListeners,
  initializeHideUntilScroll,
  overridePushState,
};

export default HELPER;
