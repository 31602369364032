import Settings from '../../helpers/settings';
import Vanilla from '../../helpers/vanilla';
import CONSTANTS from '../../helpers/constants';
import ChatWidget from '../chatWidget';
import Analytics from '../analytics';
import getEmbedExtendedHtml from './communityEmbedExtended';
import getEmbedHtml from './communityEmbed';
import { getContentEmbed, handlePreview } from './contentEmbed';
import trackEmbedsShown from './trackEmbedsShown';

const defaultSettings = {
  selector: null,
  wrapperAction: 'appendChild',
};

const selectors = [
  {
    selector: CONSTANTS.SELECTOR.communityEmbed,
    refererTrigger: 'Minimal Embed',
    eventName: 'community_embed_click',
  },
  {
    selector: CONSTANTS.SELECTOR.communityEmbedExtended,
    priority: 'guurusOnly',
    refererTrigger: 'Community Embed',
    eventName: 'community_extended_embed_click',
  },
  {
    selector: CONSTANTS.SELECTOR.contentEmbed,
    priority: 'guurusOnly',
    refererTrigger: 'Content Embed',
    eventName: 'content_embed_click',
  },
];

const prepareEmbed = function (settings) {
  const embedAutoMessages = settings.autoMessages?.filter(({
    action,
    isEnabled,
    content,
    targetDevices,
    url,
    category,
  }) => ((['communityEmbed', 'communityEmbedExtended', 'contentEmbed'].includes(action))
    && Vanilla.isForCurrentDeviceTarget(targetDevices)
    && Vanilla.hasPageTargeting(settings, url, category)
    && content?.[settings?.locale])
    && (isEnabled || (Vanilla.isPreview() && action === 'contentEmbed')));

  if (!embedAutoMessages?.length) {
    return null;
  }

  const { launcherColor, launcher: { pictures }, locale } = settings;
  return embedAutoMessages.map((embed) => ({
    ...embed,
    elementId: `${embed.action}-${embed.id}`,
    contentText: embed.content[locale || 'en'],
    buttonDescriptionText: embed?.embedButtonDescription?.[locale || 'en'],
    contentEmbedCommunityDescription: embed?.contentEmbedCommunityDescription?.[locale || 'en'],
    contentEmbedCommunityPhotoUrl: embed?.contentEmbedCommunityPhotoUrl,
    avatars: pictures.sort(() => 0.5 - Math.random()).slice(0, 3),
    launcherColor,
  }));
};

const handleEmbedClick = function (refererTrigger, eventName) {
  Settings.add('refererTrigger', refererTrigger);
  ChatWidget.showChat();
  Vanilla.addClass(document.body, 'guuru-chat-visible-auto');
  Analytics.trackEvent(eventName);
};

const getEmbedTemplate = async function (embed) {
  switch (embed.action) {
    case 'contentEmbed':
      return getContentEmbed(embed);
    case 'communityEmbedExtended':
      return getEmbedExtendedHtml(embed);
    default:
      return getEmbedHtml(embed);
  }
};

const attach = async function (embed, parent) {
  if (!parent || parent.querySelector(`#${embed.elementId}`)) return;
  const template = await getEmbedTemplate(embed);
  if (!template) {
    return;
  }

  const { action, elementId } = embed;
  const element = document.createElement('div');
  element.id = `${elementId}`;
  element.className = action;
  element.innerHTML = template;
  element.style.width = '100%';

  if (!parent || parent.querySelector(`#${embed.elementId}`)) return;
  Vanilla[defaultSettings.wrapperAction](parent, element);

  handlePreview(elementId);
};

const attachImmediately = function (embed, selector) {
  const wrapper = document.querySelector(selector);
  return attach(embed, wrapper);
};

const waitToAttach = function (embed, selector) {
  setTimeout(() => attachImmediately(embed, selector), 3000);
};

const urlMatchesHref = (url) => {
  const currentPageUrl = window.location.href;
  const rePattern = new RegExp(url, 'g');
  return rePattern.test(currentPageUrl);
};

const addEmbed = async function (embed) {
  const { url: embedUrl, embedSelector } = embed;
  if (!embedSelector) return;

  if (embedUrl) {
    const currentPageUrlMatches = urlMatchesHref(embedUrl);

    if (!currentPageUrlMatches) {
      Vanilla.debugMessage(`Embed not attached (reason: embedUrl=${embedUrl})`);
      return;
    }
  }

  await attachImmediately(embed, embedSelector);
  waitToAttach(embed, embedSelector);
};

const attachEmbeds = async function () {
  const settings = Settings.getAll();

  const embeds = prepareEmbed(settings);
  if (!embeds) {
    return;
  }

  await Promise.all(embeds.map((embed) => addEmbed(embed)));

  selectors.forEach(({
    selector,
    priority,
    refererTrigger,
    eventName,
  }) => (
    Vanilla.live(
      `.${selector}`,
      ['click'],
      () => {
        if (priority) {
          Settings.add('priority', priority);
        }
        handleEmbedClick(refererTrigger, eventName);
      },
    )
  ));
  trackEmbedsShown();
};

const HELPER = {
  defaultSettings,
  attachEmbeds,
};

export default HELPER;
