// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable selector-id-pattern, selector-class-pattern */

.invitation-invitation-grabber-content-R35Pz {
  box-shadow: 0 0 4px rgba(0 0 0 / 15%);
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 24px;
  border-radius: 5px;
  max-width: 300px;
  flex-direction: column;
  display: flex;
  cursor: default;
  background-color: white;
  line-height: 1.6;
}

.invitation-invitation-grabber-content-R35Pz h2 {
  margin: 0 0 12px;
  line-height: 1.5;
  font-weight: 500;
  font-size: 22px;
}

.invitation-invitation-grabber-content-R35Pz h3 {
  display: none;
}

.invitation-invitation-grabber-content-R35Pz > i {
  display: none;
}

.invitation-invitation-grabber-content-R35Pz ol {
  margin: 4px 0 12px;
  padding-left: 0;
  font-size: 16px;
  list-style-position: inside;
}

.invitation-invitation-grabber-content-R35Pz a {
  display: block;
  text-align: center;
  width: 100%;
  padding: 11px 20px;
  margin-top: 18px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  pointer-events: all;
  align-self: center;
  text-decoration: none;
  box-sizing: border-box;
  font-weight: 400;
}

.invitation-invitation-grabber-content-R35Pz a:hover {
  opacity: 0.8;
}

.invitation-invitation-grabber-content-R35Pz p {
  margin: 0;
  font-size: 16px;
}

.invitation-invitation-grabber-content-R35Pz span {
  color: #000 !important;
  font-size: 14px;
  width: 100%
}

.invitation-invitation-grabber-content-R35Pz span::before {
  display: none;
}

.mod--type-squared .invitation-invitation-grabber-content-R35Pz {
  border-radius: 0;
}

.invitation-invitation-grabber-content-R35Pz i {
  display: flex;
  background-color: rgba(0 0 0 / 6%);
  margin-left: 20px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.invitation-invitation-grabber-content-R35Pz i > svg {
  margin: 5px 0 0 7px;
}

.invitation-invitation-grabber-message-M2DSH {
  font-size: 13px;
  font-weight: 300;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .invitation-invitation-grabber-message-M2DSH {
    line-height: 1.3;
  }

  .invitation-invitation-grabber-content-R35Pz ol {
    display: none;
  }

  .invitation-invitation-grabber-content-R35Pz p:first-of-type {
    display: none;
  }

  .invitation-invitation-grabber-content-R35Pz h3 {
    display: initial;
  }

  .invitation-invitation-grabber-content-R35Pz h2 {
    margin: 0 0 5px;
    font-size: 14px;
  }

  .invitation-invitation-grabber-content-R35Pz a {
    font-size: 12px;
  }

  .invitation-invitation-grabber-content-R35Pz {
    max-width: 210px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invitation-grabber-content": `invitation-invitation-grabber-content-R35Pz`,
	"invitationGrabberContent": `invitation-invitation-grabber-content-R35Pz`,
	"invitation-grabber-message": `invitation-invitation-grabber-message-M2DSH`,
	"invitationGrabberMessage": `invitation-invitation-grabber-message-M2DSH`
};
export default ___CSS_LOADER_EXPORT___;
