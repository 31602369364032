import Analytics from '../../../analytics';
import Events from '../../../events';
import CONSTANTS from '../../../../helpers/constants';
import style from './index.css';
import Vanilla from '../../../../helpers/vanilla';

const anchorElement = (text, mentionsCount) => {
  const anchor = `
<div class="${style.anchor}">
  <a id="guuru-content-anchor">${text} (${mentionsCount})</a>
</div>`;

  const wrapper = document.createElement('div');
  wrapper.innerHTML = anchor;
  return wrapper.firstElementChild;
};

const appendAnchor = function (
  embed,
  mentionsCount,
  textContent,
  locale = 'en',
) {
  const { embedSelector, embedAnchorSelector } = embed;

  if (!embedSelector || !embedAnchorSelector) {
    return;
  }

  const anchor = document.querySelector(embedAnchorSelector);
  if (!anchor) {
    Vanilla.debugMessage('Content anchor selector not found', { embedAnchorSelector });
    return;
  }

  anchor.appendChild(anchorElement(textContent?.[locale], mentionsCount));

  Vanilla.live('#guuru-content-anchor', 'click', () => {
    const selector = document.querySelector('#content-embed-headline');
    selector.scrollIntoView({ behavior: 'smooth', block: 'start' });
    Analytics.trackEvent('content_embed_anchor_click');
    Events.processEvent({
      id: `${CONSTANTS.GUURU_EVENT_NAMESPACE}embed:interaction`,
      data: { type: 'content', element: 'anchor' },
    });
  });
};

export default appendAnchor;
